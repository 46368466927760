$color-white: #fff;
$color-black: #000;
$color-text: $color-black;

.module-notification {
  position: relative;
  padding: 1em 2em 1em 2em;
  font-size: 16px;
  text-align: center;
  color: $color-white;
  
  &.-cookie {
    display: none;
  }

  p {
    margin-bottom: 0;

    a {
      border-bottom: 1px solid #fff;
      &:hover { border-width: 0 0 2px 0; }
    }
  }

  &.-cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5em 8em 1.5em 2em;
    background-color: rgba($color-black, .9);
    background-image: none;
    color: $color-white;
    z-index: 99999;

    a {
      color: #e7e7e7;
      text-decoration: underline!important;
    }

    .close {
      padding: .35em .35em .3em;
      border: 1px solid $color-white;
      background-color: $color-white;
      color: $color-text;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      line-height: 1;

      &:hover {
        background-color: $color-text;
        color: $color-white;
      }
    }
  }

  .close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1em;

    i { transition: transform .2s; }

    &:hover {
      cursor: pointer;
      i { transform: scale(1.3); }
    }
  }
}