.type--contact {

	form > div { margin-bottom: 16px; }

	@include media('>=medium') {
	  .contact-prenom,
	  .contact-nom,
	  .contact-tel,
	  .contact-mail {
	  	@include span-columns(6 of 12);

	  	input { max-width: 100%; }
	  }

	  .contact-nom,
	  .contact-tel {
	  	@include omega;
	  }
  }

  form {
  	padding-bottom: 32px;

  	@include media('>=large') {
  		max-width: 800px;
  		margin-left: auto;
  		margin-right: auto;
  	}
  }

  input[type="submit"] {
  	font-size: 18px;
  	border-radius: 0;
  	background-color: $color-white;
  	text-transform: uppercase;
  }
}