.type {

  &-header {
    padding: 3em 2em;
    margin-bottom: 4em;
    background-color: $color-dg-grey;
    color: $color-white;
    @include media('<medium') {
      padding: 1em 1em;
    }
  }

  &-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    @include media('<medium') {
      padding: 1em 1em;
      font-size: 28px;
    }
  }
}