.offre {

  &-content {
    width: 100%;
    margin-bottom: 3em;
    @include clearfix;
    @include media('>=larger') {
      position: relative;
    }
  }

  &-figure {
    margin-bottom: 2em;

    @include media('>=large') {
      @include span-columns(6 of 12);
      margin-bottom: 0;
    }

    img { display: block; }
  }

  &-title {
    margin-bottom: 1em;
    padding: 8px 0 6px;
    border-width: 1px 0;
    border-style: solid;
    border-color: $color-dg-grey;
    color: $color-text;
    font-size: 32px;
    line-height: 1.3;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
  }

  &-description {
    padding: 0 1em;
    font-size: 18px;

    @include media('>=large') {
      @include span-columns(6 of 12);
      @include omega;
    }

    ul {
      padding-left: 1em;
      list-style-type: disc;

      li { padding-left: 0; }
    }

    .btn {
      display: block;
      margin-top: 8px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
      background-color: $color-white;
      color: $color-text;

      &:first-of-type { margin-top: 32px; }

      @include media('>=larger') {
        text-align: right;
      }


      span {
        display: inline-block;
        padding: 8px 16px;
        border-width: 1px;
        border-style: solid;
        border-color: $color-dg-grey;
      }

      &:hover span {
        background-color: $color-text;
        color: $color-white;
      }
    }
  }


}