.social {

	@include span-columns(6);
	@include omega;
	margin-top: 1.5em;

	@include media('>=medium') {
		@include span-columns(8);
	}

	&-icon {
		fill: $color-green;
		width: 16px;
		height: 16px;
		position: relative;
		top: -2px;

		@include media('>=medium') {
			width: 24px;
			height: 24px;
			top: 0;
		}
	}

	&-link {
		box-sizing: content-box;
		display: block;
		width: 16px;
		height: 16px;
		padding: 4px;
		margin-left: .5em;
		float: right;
		border-radius: 50%;
		border: 1px solid $color-green;
		background-color: $color-white;

		@include media('>=medium') {
			width: 24px;
			height: 24px;
		}

		&:hover {
			background-color: $color-green;

			> .Social-icon { fill: $color-white; }
		}
	}
}