.footer {
	padding-top: 4em;
	@include font-size(1.2);
	overflow: hidden;
	clear: both;

	a {
		color: $color-text;

		&:hover { color: $color-green;}
	}

	.copyright {
		margin-top: 2em;
	}
}