/*!
Theme Name: dg
Description: Theme for Destination Giveny
Author: Eric Poupy & Jean-Baptiste Louvet
Version: 0.1
*/


/* libraries */
@import 'vendors/bourbon/bourbon';
@import 'vendors/neat/neat';
@import 'vendors/fontawesome/font-awesome';
@import 'vendors/fancybox/core';

/* mixins, functions and stuff */
@import 'utils/mixins';

/* variables */
@import 'var/colors';
@import 'var/typography';
@import 'var/grid';

/* reset */
@import 'reset';

/* utils */
@import 'utils/includeMedia';
@import 'utils/utils';

@import 'modules/Zindex';

/* basic styling of elements */
@import 'base/elements';
@import 'base/links';

/* layout */
@import 'modules/Site';
@import 'modules/Wrapper';
@import 'modules/Header';
@import 'modules/nav';
@import 'modules/hero';
@import 'modules/lead';
@import 'modules/type';
@import 'modules/offre';
@import 'modules/contact';
@import 'modules/Page';

@import 'modules/Footer';
  @import 'modules/Social';

@import 'modules/module-notification';

/*print */
@import 'base/print';