.fancybox-enabled {
	overflow: hidden;
}

.fancybox-enabled body {
    overflow: visible;
	height: 100%;
}

.fancybox-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99993;
	backface-visibility: hidden;
}

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
	z-index: 99992;
}

.fancybox-bg {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #0f0f11;
	opacity: 0;
	transition-timing-function: cubic-bezier(.55,.06,.68,.19);
	backface-visibility: hidden;
}

.fancybox-container--ready .fancybox-bg {
	opacity: 0.87;
	transition-timing-function: cubic-bezier(.22,.61,.36,1);
}

.fancybox-controls {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	text-align: center;
	opacity: 0;
	z-index: 99994;
	transition: opacity .2s;
	pointer-events: none;
	backface-visibility: hidden;
	direction: ltr;
}

.fancybox-show-controls .fancybox-controls {
	opacity: 1;
}

.fancybox-infobar {
	display: none;
}

.fancybox-show-infobar .fancybox-infobar {
	display: inline-block;
	pointer-events: all;
}

.fancybox-infobar__body {
	display: inline-block;
	width: 70px;
	line-height: 44px;
	font-size: 13px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	text-align: center;
	color: #ddd;
	background-color: rgba(30,30,30,0.7);
	pointer-events: none;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-buttons {
	position: absolute;
	top: 0;
	right: 0;
	display: none;
	pointer-events: all;
}

.fancybox-show-buttons .fancybox-buttons {
	display: block;
}

.fancybox-slider-wrap {
	overflow: hidden;
	direction: ltr;
}

.fancybox-slider-wrap,
.fancybox-slider {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	margin: 0;
	z-index: 99993;
	backface-visibility: hidden;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

.fancybox-slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: auto;
	outline: none;
	white-space: normal;
	box-sizing: border-box;
	text-align: center;
	z-index: 99994;
	-webkit-overflow-scrolling: touch;
}

.fancybox-slide::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 0;
}

.fancybox-slide > * {
	display: inline-block;
	position: relative;
	padding: 24px;
	margin: 44px 0 44px;
	border-width: 0;
	vertical-align: middle;
	text-align: left;
	background-color: #fff;
	overflow: auto;
	box-sizing: border-box;
}

.fancybox-slide--image {
	overflow: hidden;
}

.fancybox-slide--image::before {
	display: none;
}

.fancybox-content {
	display: inline-block;
	position: relative;
	margin: 44px auto;
	padding: 0;
	border: 0;
	width: 80%;
	height: calc(100% - 88px);
	vertical-align: middle;
	line-height: normal;
	text-align: left;
	white-space: normal;
	outline: none;
	font-size: 16px;
	font-family: Arial, sans-serif;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-overflow-scrolling: touch;
}

.fancybox-iframe {
	display: block;
	margin: 0;
	padding: 0;
	border: 0;
	width: 100%;
	height: 100%;
	background: #fff;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
	background: transparent;
}

.fancybox-placeholder {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	border: 0;
	z-index: 99995;
	background: transparent;
	cursor: default;
	overflow: visible;
	transform-origin: top left;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	backface-visibility: hidden;
}

.fancybox-image,
.fancybox-spaceball {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	max-width: none;
	max-height: none;
	background: transparent;
	background-size: 100% 100%;
}

.fancybox-controls--canzoomOut .fancybox-placeholder {
	cursor: zoom-out;
}

.fancybox-controls--canzoomIn .fancybox-placeholder {
	cursor: zoom-in;
}

.fancybox-controls--canGrab .fancybox-placeholder {
	cursor: grab;
}

.fancybox-controls--isGrabbing .fancybox-placeholder {
	cursor: grabbing;
}

 .fancybox-spaceball {
	z-index: 1;
}

.fancybox-tmp {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

.fancybox-error {
	position: absolute;
	margin: 0;
	padding: 40px;
	top: 50%;
	left: 50%;
	width: 380px;
	max-width: 100%;
	transform: translate(-50%, -50%);
	background: #fff;
	cursor: default;
}

.fancybox-error p {
	margin: 0;
	padding: 0;
	color: #444;
	font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 44px;
	padding: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	outline: none;
	background: transparent;
	z-index: 10;
	cursor: pointer;
}

.fancybox-close-small:after {
	content: '×';
	position: absolute;
	top : 5px;
	right: 5px;
	width: 30px;
	height: 30px;
	font: 20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
	color: #888;
	font-weight: 300;
	text-align: center;
	border-radius: 50%;
	border-width: 0;
	background: #fff;
	transition: background .2s;
	box-sizing: border-box;
	z-index: 2;
}

.fancybox-close-small:focus:after {
	outline: 1px dotted #888;
}

.fancybox-slide--video .fancybox-close-small {
	top : -36px;
	right : -36px;
	background: transparent;
}

.fancybox-close-small:hover:after {
	color: #555;
	background: #eee;
}

/* Caption */

.fancybox-caption-wrap {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 60px 30px 0 30px;
	z-index: 99998;
	backface-visibility: hidden;
	box-sizing: border-box;
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 20%, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.8) 100%);
	opacity: 0;
	transition: opacity .2s;
	pointer-events: none;
}

.fancybox-show-caption .fancybox-caption-wrap {
	opacity: 1;
}

.fancybox-caption {
	padding: 30px 0;
	border-top: 1px solid rgba(255,255,255,0.4);
	font-size: 14px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	color: #fff;
	line-height: 20px;
	-webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button {
	pointer-events: all;
}

.fancybox-caption a {
	color: #fff;
	text-decoration: underline;
}

/* Buttons */

.fancybox-button {
	display: inline-block;
	position : relative;
	width: 44px;
	height: 44px;
	line-height: 44px;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	cursor: pointer;
	background: transparent;
	color: #fff;
	box-sizing: border-box;
	vertical-align: top;
	outline: none;
}

.fancybox-button--disabled {
	cursor: default;
	pointer-events: none;
}

.fancybox-infobar__body, .fancybox-button {
	background: rgba(30,30,30,0.6);
}

.fancybox-button:hover {
	background: rgba(0,0,0,0.8);
}

.fancybox-button::before,
.fancybox-button::after {
	content: '';
	pointer-events: none;
	position: absolute;
	border-color: #fff;
	background-color: currentColor;
	color: currentColor;
	opacity: 0.9;
	box-sizing: border-box;
	display: inline-block;
}

.fancybox-button--disabled::before,
.fancybox-button--disabled::after {
	opacity: 0.5;
}

.fancybox-button--left::after {
	left: 20px;
	top: 18px;
	width: 6px;
	height: 6px;
	background: transparent;
	border-top: solid 2px currentColor;
	border-right: solid 2px currentColor;
	transform: rotate(-135deg);
}

.fancybox-button--right::after {
	right: 20px;
	top: 18px;
	width: 6px;
	height: 6px;
	background: transparent;
	border-top: solid 2px currentColor;
	border-right: solid 2px currentColor;
	transform: rotate(45deg);
}

.fancybox-button--left {
	border-bottom-left-radius: 5px;
}

.fancybox-button--right {
	border-bottom-right-radius: 5px;
}

.fancybox-button--close {
	float: right;
}

.fancybox-button--close::before, .fancybox-button--close::after {
	content: '';
	display: inline-block;
	position: absolute;
	height: 2px;
	width: 16px;
	top: calc(50% - 1px);
	left: calc(50% - 8px);
}
.fancybox-button--close::before {
	transform: rotate(45deg);
}
.fancybox-button--close::after {
	transform: rotate(-45deg);
}
/* Loading spinner */

.fancybox-loading {
	border: 6px solid rgba(100, 100, 100, .4);
	border-top: 6px solid rgba(255, 255, 255, .6);
	border-radius: 100%;
	height: 50px;
	width: 50px;
	animation: fancybox-rotate .8s infinite linear;
	background: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -25px;
	z-index: 99999;
}

@keyframes fancybox-rotate {
	from {transform: rotate(0deg);}
	to {transform: rotate(359deg);}
}

/* Styling for Small-Screen Devices */

@media all and (max-width: 800px) {

	.fancybox-controls {
		text-align: left;
	}

	.fancybox-button--left,
	.fancybox-button--right,
	.fancybox-buttons button:not(.fancybox-button--close)
	{
		display: none !important;
	}

	.fancybox-caption {
		padding: 20px 0;
		margin: 0;
	}
}
