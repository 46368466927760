html, body { height: 100%; }

body.hasMenuOpen,
body.hasSplashOpen {
	overflow: hidden;
}

.site {
	width: 100%;
	// max-width: $max;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 2em;
	background-color: #fff;
}

.site {
	@include font-size(1.6);
	line-height: 1.5;
}