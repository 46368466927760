.hero {

	figure {
		width: 100%;
		max-height: 450px;
		overflow: hidden;

		img { width: 100%;}

		@include media('<medium') {
			min-height: 300px;

			img {
				height: 300px;
				width: auto;
				max-width: initial;
			}
		}
	}
}