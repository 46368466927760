// Rem output with px fallback
@mixin font-size($sizeValue: 1.6) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rem($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value * 10}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  } 

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  } 
  
  #{$property}: $pxValues; 
  #{$property}: $remValues; 
}

// Box model
@mixin box-sizing($box-model) {
	box-sizing: $box-model;
}

// Border radius
@mixin border-radius($radius) {
	background-clip: padding-box; /* stops bg color from leaking outside the border: */
	border-radius: $radius;
}

// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
  clear: both;
}

@mixin inline-block() {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}


/*
 *
 * Ratio box
 * http://davidtheclark.github.io/scut/#ratio-box
 *
 */
@mixin RatioBox ($ratio: 1/1, $inner: ".RatioBox-inner") {
  overflow: hidden;
  position: relative;

  // The container's height, as a percentage of the
  // container's width, is set by assigning
  // padding-top to a pseudo-element.
  &:before {
    content: "";
    display: block;
    height: 0;
    padding-top: (1 / $ratio) * 100%;
  }

  // The inner element simply fills up the container.
  & > #{$inner} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

%RatioBox { @include RatioBox; }

/*
 *
 * Font Smoothing
 * http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 *
 */

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

/*
 *
 * Media Queries mixin (max or min width)
 * with ie in mind. see ie.scss
 *
 */


$fix-mqs: false !default;

@mixin respond-min($width) {
  @if $fix-mqs {
    @if $fix-mqs >= $width { @content; }
  }
  @else {
    @media screen and (min-width: $width) { @content; }
  }
}

@mixin respond-max($width) {
  @if $fix-mqs {
    @if $fix-mqs <= $width { @content; }
  }
  @else {
    @media screen and (max-width: $width) { @content; }
  }
}

$old-ie: false !default;

@mixin old-ie {
  @if $old-ie { @content; }
}


// =============================================================================
// String Replace https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
// =============================================================================

// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}