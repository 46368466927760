a {
	color: $color__link;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $color-grey-dark;
		text-decoration: none;
		transition: color .1s;
	}

	&:visited { color: $color__link-visited; }

	&:focus { outline: none; }

	&[href^=tel],
	&[href^=fax] {
		cursor: default;
		color: $black;
		text-decoration: none!important;

		&:hover { text-decoration: none;}
	}

  a[href^=mailto] { word-break:break-all; }

}