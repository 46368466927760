$navbar-content-height: 60px;
$navbar-spacing: 10px;
$navbar-height: 80px;
$navigation-color-hover: $color-black;

// Mobile view
.menu-button {
  display: block;
  margin-top: 16px;
  position: relative;
  width: 32px;
  height: 32px;
  float: right;
  color: $color-dg-grey;
  line-height: 32px;
  text-decoration: none;
  z-index: index($els, menu-link);

  @include media('>=navbar-expanded') { display: none; }

  svg {
    width: $navbar-content-height;
    height: $navbar-content-height;
    fill: $color-text;
  }

  &:focus,
  &:hover {
    color: $navigation-color-hover;
  }
}

// Nav menu
.menu {
	float: right;
}

.top {
  background-color: $color-dg-grey;
  color: $color-white;
  @include media('<navbar-expanded') {
    display: none;
  }
}

.menu-list-top {
  padding: 6px 16px;
  font-size: 13px;
  line-height: 1;
  text-align: right;
  margin-bottom: 0;

  li {
    display: inline-block;
    margin: 0 8px;
  }

  a {
    color: $color-white;
    &.active { color: $color-grey-light;}
  }
}

.page .menu-list-top,
.error404 .menu-list-top {

  li {
    display: none!important;

    &.lang-item {
      display: inline-block!important;
    }
  }
}

// Nav list
.menu-list {
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;

  @include media('<navbar-expanded') {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background-color: $color-white;
    text-align: right;
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
    overflow: scroll;
  }

  &.show {
    @include media('>=navbar-expanded') {
      display: block;
    }
  }

  .menu-item {
    display: block;
    float: left;
    line-height: 60px;
    text-transform: uppercase;

    &.isOffCanvas,
    &.lang-item { display: none; }

    &.lang-item {
      text-transform: uppercase;
    }

    @include media('<navbar-expanded') {
      width: 100%;
      float: none;
      line-height: 40px;

      &.isOffCanvas,
      &.lang-item { display: block; }
    }

    > a {
      padding: 10px;
      color: $color-dg-grey;
      font-weight: 700;

      @include media('<navbar-expanded') {
        display: block;
        width: 100vw;
        padding: 5px 32px;

        &:hover {
          background-color: $color-dg-grey;
          color: $color-white;
        }
      }

      @include media('>=navbar-expanded') {

        &.active { color: $color-black;}
      }
    }
  }
}

.page .menu-list .menu-item,
.error404 .menu-list  .menu-item {
  @include media('<navbar-expanded') {
    display: none!important;
    &.lang-item { display: block!important; }
  }
}