.lead {
	padding: 3em 1em;
	text-align: center;

	@include media('<medium') {
		padding: 2em 1em;
		text-align: left;
	}

	&-title {
		padding: 0 1em 1em;
		font-size: 32px;
		font-weight: 800;
		line-height: 1.3;
		text-transform: uppercase;

		@include media('<medium') {
			padding: 0 0 1em;
			font-size: 24px;
		}
	}

	&-content {
		max-width: 50em;
		margin: 0 auto;
    font-size: 18px;

    .btn {
      display: block;
      margin-top: 8px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
      background-color: $color-white;
      color: $color-text;

      &:first-of-type { margin-top: 32px; }

      // @include media('>=larger') {
      //   text-align: right;
      // }

      span {
        display: inline-block;
        padding: 8px 16px;
        border-width: 1px;
        border-style: solid;
        border-color: $color-dg-grey;
      }

      &:hover span {
        background-color: $color-text;
        color: $color-white;
      }
    }

	}

	&--infos-pratiques {
		text-align: left;
		padding-top: 0;

		.addresses {
			overflow: hidden;
		}

		.addresse {
			margin-bottom: 2em;

			@include media('<medium') {
				@include span-columns(6 of 12);
				@include omega(2n);
			}
			@include media('>=medium') {
				@include span-columns(4 of 12);
				@include omega(3n);
			}
		}

		h3 {
			text-transform: uppercase;
			margin: 0 0 1em;
			font-size: 24px;
			font-weight: 700;
		}
	}
}