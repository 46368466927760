.header {
	width: 100%;
	height: 60px;
	@include media('>=navbar-expanded') { height: 84px; }
	box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
	position: fixed;
	top: 0;
	background-color: $color-white;
	z-index: 1000;

	// @include media('<1300') {
	// 	padding: 0 16px;
	// }

	.logo {
		float: left;

		&-link {
			display: block;
			overflow: hidden;
			width: auto;
			color: $color-dg-grey;

			.logo-img {
				float: left;
				width: 50px;
				height: 50px;
				margin: 5px 8px 0 0;
			}
			.logo-txt {
				float: left;
				font-size: 24px;
				margin-top: 2px;
				font-weight: 400;
				line-height: 58px;
				text-transform: uppercase;

				span { font-weight: 700; }

				@include media('<=medium') {
					margin-top: 16px;
					font-size: 16px;
					line-height: 16px;
					span {
						display: block;
					}
				}
			}
		}
	}
}