body .page {
  margin-top: 5em;
	padding: 2em 0;
	max-width: 600px;

	& > .title {
		text-transform: uppercase;
		@include font-size(2.4);
		font-weight: 700;
		margin-bottom: 1em;
	}

	& > .copy {

		strong u {
			text-transform: uppercase;
			text-decoration: none;
    }
    
    h2 {
		  font-weight: 700;
      margin-bottom: 1em;
    }
	}
}